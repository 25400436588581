export default {
    primaryKey: "id",
    model: 'city',
    order: 'id',
    sort: 'desc',
    actions: [
        "add",
        "edit",
        "delete",
    ],
    showTotalRecords: true,
    deletemessage: 'confirm_deletion',
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true,
            filter: true,
        },
        {
            id: 'name',
            title: 'title',
            table: true,
            sortable: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true
            },
            filter: true
        },
        {
            id: 'idc',
            title: 'country',
            table: true,
            sortable: false,
            filter: true,
            filter_model_select: true,
            table_show: 'country.name',
            form: {
                type: "select",
                select_data: {
                    "title": "name",
                },
            }
        },
    ]
}
