export default {
    absent: "Mavjud emas",
    abonplata: "Abonent to‘lovi",
    access_restriction: "Kirish cheklash",
    accountant_fio: "Bosh hisobchi FIO",
    accrue_bonuses_on_bonus_payments: "Bonuslar orqali to'lash uchun bonuslarni hisoblang",
    activate_bonus_system: "Bonus tizimini faollashtirish",
    acquiring_fee: "Acquiring to‘lovi",
    add_field: "Yo'nalish qo'shing",
    add_user: "Foydalanuvchi qo'shish",
    additional_doctors: "Qo‘shimcha shifokorlar",
    additional_modules: "Qo‘shimcha modullar",
    additional_space: "Qo‘shimcha joy",
    admins_limit: "Administratorlar chegarasi",
    Advance: "Depozit",
    advance_calculated: "Depozit",
    advance_payment: "Bir martalik to‘lov",
    after_discounts: "Chegirmalar hisobga olinganidan keyin",
    after_technic_deduction: "Texnik hisobdan chiqarilganidan keyin",
    after_visit: "Tashrifdan keyin eslatma",
    akt_text: "Ko'rsatilgan xizmatlar sertifikatida eslatma",
    all_administrators: "Barcha administratorlar",
    all_visits: "Barcha yozuvlar",
    allow: "Ruxsat etish",
    allowed_ips: "Hisobingizga kirishingiz mumkin bo'lgan IP manzillar",
    allow_negative_deposits: "Depozitlarni salbiy deb hisobdan chiqarishga ruxsat bering",
    api: "API",
    api_token: "API Token",
    are_you_sure_you_want_to_delete_branch: "Haqiqatan ham filialni o‘chirib tashlamoqchimisiz?",
    are_you_sure_you_want_to_delete_company: "Haqiqatan ham kompaniyani o‘chirib tashlamoqchimisiz?",
    are_you_sure_you_want_to_delete_notification: "Haqiqatan ham bildirishnomani o‘chirib tashlamoqchimisiz?",
    are_you_sure_you_want_to_delete_source: "Haqiqatan ham manbani o‘chirib tashlamoqchimisiz?",
    are_you_sure_you_want_to_delete_tag: "Haqiqatan ham belgini o‘chirib tashlamoqchimisiz?",
    ask_for_more: "Qayta so‘ramoq",
    ask_reason_cancel: "Tashrifni bekor qilishda sababni so'rang",
    ask_reason_not_come: "'Kelmadi' holatining sababini so'rang",
    ask_reason_reschedule: "Tashrifni kechiktirish sababini so'rang",
    assistants_connected: "Ulangan modul yordamchilari",
    available_disk_space: "Diskda mavjud joy, MB (0 - chegarasiz)",
    automatic_conflict_task_creation: "Jadvalda ziddiyat yuzaga kelganda avtomatik ravishda vazifalar yaratish",
    before_discounts: "Chegirmalarni hisobga olishdan oldin",
    before_technic_deduction: "Texnik hisobdan chiqarilganidan oldin",
    before_visit: "Tashrifdan oldin eslatma",
    before_visit_day_in_advance: "Eslatmaga bir kun oldin tashrif buyuring (darhol)",
    billing: "Billing",
    billing_payment: "To‘lov",
    bonus_accrued: "Hisoblangan bonuslar",
    bonus_adjustment: "Bonus sozlamalari",
    bonus_payment: "Bonus to'lovi",
    bonus_percent_can_be_used: "Bonuslar bilan to'lanishi mumkin bo'lgan savdoning maksimal ulushi",
    bonus_percent_of_cheque: "Sotish bonusi ulushi",
    bonus_system: "Bonus tizimi",
    bonus: "Bonus",
    bonuses: "Bonuslar",
    branch_address: "Filial manzili",
    branch_contacts: "Filial aloqalari",
    branch_inn: "Filial INN",
    branches: "Filiallar",
    branches_connected: "Ulangan pullik modul filiallari",
    by_chairs: "O‘rindiq bo‘yicha",
    calendar_text_color: "Kalendardagi matn rangi",
    calendar_view_by_default: "Oddiy kalendar ko‘rinishi",
    cancel_and_move_reason: "Bekor qilish va ko‘chirish sababi",
    cancel_and_move_reasons: "Bekor qilish va ko‘chirish sabablari",
    change_tariff: "Tarifni almashtirish",
    ClientNote: "Eslatma",
    client_tag: "Belgi",
    clinic: "Klinika",
    clinics: "Klinikalar",
    clinic_data: "Klinika ma‘lumotlari",
    clinic_inn: "Klinika INN",
    clinic_is_blocked: "Klinika bloklangan",
    clinic_name: "Klinik nomlanishi",
    clinic_name_in_cheque: "Chekda klinik nomi",
    close: "Yopish",
    cloud_space: "Bulutdagi joy",
    cities: "Shaharlar",
    city: "Shahar",
    communications: "Aloqalar",
    company_data: "Kompaniya ma‘lumotlari",
    connected_successfully: "Muvaffaqiyatli ulandi",
    connection_failed: "Aloqa xatosi",
    control_system: "Nazorat tizimi",
    countries: "Mamlakatlar",
    country: "Mamlakat",
    crm_application_created: "CRM arizasini yaratish",
    crm_connected: "CRM pulli modul ulangan",
    currency_rates: "Valyuta kurslari",
    data: "Ma’lumotlar",
    date_of_registration: "Ro‘yxatga olish sanasi",
    day: "Kun",
    deduct_acquiring_from_salary: "Acquiring to‘lovini ish haqidan chiqaring",
    deduct_warranty_from_salary: "Kafolat ostidagi xodimlarga ish haqini to'lamang",
    deleted: "O‘chirib tashlash",
    demo: "Demo",
    director_data: "Direktor ma‘lumotlari",
    director_fio: "Direktor FIO",
    disk_space_limit: "Disk joyi chegarasi (Mb)",
    do_not_deduct_acquiring_from_technic_absolute: "Mutlaq hisob-kitob bilan texnik xodimlardan sotib olishni ayirmang",
    do_not_send_notifications_if_period_passed: "Belgilangan muddat allaqachon o'tib ketgan bo'lsa, tashrif haqida eslatma yubormang",
    Doctor: "Shifokor",
    DoctorAssistant: "Yordamchi",
    doctors_limit: "Shifokor chegarasi",
    egisz_prod: "EGISZ jangovar rejimda",
    enter_ip_address: "IP manzilini kiriting",
    error_code: "Xatolik kodi",
    finance: "Moliyalar",
    free: "Bo‘sh",
    free_day: "Bepul kun",
    general: "Umumiy",
    has_additional_doctors: "Qo'shimcha shifokorlar bor",
    higher_number_higher_priority: "Raqam qanchalik baland bo'lsa, ro'yxatdagi variant shunchalik yuqori bo'ladi",
    hour: "soat",
    hours: "soatlar",
    image_hidden: "Rasm yashirilgan",
    in_development: "Ishlab chiqarishda",
    insurance_company: "Sug‘urta kompaniyasi",
    integrations: "Integrasiya",
    interface: "Interfeys",
    ioka: "Ioka",
    ip_note: "Dasturga kirishni yo'qotmaslik uchun IP-manzilni kiritishda ehtiyot bo'ling. Klinikada statik IP manzilingiz bo'lsa, foydalanish mumkin.",
    jetapi_connected: "Ulangan modul JET API",
    'kit materials sync': "Materiallarni to‘plamga bog‘lash",
    kpp: "KPP",
    last_login: "Oxirgi kirish",
    legal_address: "Yuridik manzil",
    legal_name: "Yuridik nom",
    license: "Lisenziya",
    license_issue_body: "Litsenziyani kim bergan",
    license_issue_date: "Litsenziya berilgan sana",
    license_num: "Lisenziya #",
    location_on_map: "Kartada joylashuvi",
    logs: "Loglar",
    logs_journal: "Harakatlar jurnali",
    logo: "Logotip",
    logout_interval_timeout: "Faollik mavjud bo‘lmagan taqdirda dasturdan chiqish intervali (administrator hisob yozuvlari uchun)",
    max_doctor_amount: "Shifokorlarning maksimal soni",
    Manager: "Administrator",
    manager2: "Administrator",
    manual: "Qo‘lda",
    min: "daq",
    min_symbols: "Minimal belgilar",
    minute: "daqiqa",
    minutes: "daqiqalar",
    model_id: "Model Id",
    model_type: "Model turi",
    month: "Oy",
    monthly_payment: "Oylik to‘lov",
    months_to_consider_patient_lost: "Bemor yo'qolgan deb hisoblangandan so‘ng oylar soni",
    more_than_one_day: "Bir kundan ko‘p",
    more_than_one_visit: "Bir nechta tashrif",
    next_payment: "Kelgusi to‘lov",
    not_connected: "Ulanmagan",
    notification_channel: "Yetkazib berish turi",
    notification_setting: "Bildirishnoma",
    NotificationSetting: "Bildirishnoma",
    notification_type: "Bildirishnoma turi",
    notifications: "Bildirishnomalar",
    occupied: "Band",
    of_few_hours: "soat",
    ogrn: "ОGRN",
    oid_ministry_rf: "OID Vazirlar Mahkamasi",
    on: "Yoqilgan",
    on_pay_page: "To‘lov betida",
    online_booking: "Onlayn buyurtma",
    online_booking_activate: "Onlayn buyurtma faollashtirish",
    online_booking_categories: "Xizmat toifalarini ko'rsatish",
    online_booking_feedbacks: "Ko'rib chiqish funksiyasini faollashtiring",
    online_booking_map: "Filial xaritasini ko'rsatish",
    online_booking_max_days: "Maksimal yozib olish muddati kunlarda",
    online_booking_time: "Taqvimdagi vaqtni bron qiling yoki yo'q",
    only_after_accordance_with_trustme: "Trustme.kz bilan mos kelishdan keyin",
    only_after_payment_received: "Faqat bemordan to‘lov olgandan keyin",
    paid_functionality: "Pullik funksional",
    paid_till: "Muddatgacha to‘langan",
    Patient: "Bemor",
    patient_conditions: "Bemor holati",
    patient_sources: "Bemor manbalari",
    patients_import: "Bemorlar importi",
    pay: "To‘lash",
    payable: "To'langan",
    Payment: "To‘lov",
    payment_id: "To‘lov Id",
    pay_methods: "To'lov usullari",
    pay_methods_acquiring: "Ekvaying terminaliga o'tkazish uchun to'lov usullari",
    pay_methods_cash: "Naqd pul sifatida ko'rsatilgan to'lov usullari",
    pay_system: "To‘lov tizimi",
    percent_accrual_for_administrator: "Administratorlarga foiz o‘tkazish",
    percent_accrual_for_personnel: "Xodimlarga foiz o‘tkazish",
    permanent_patients_hint: "Agar bemorni doimiy deb hisoblash kerak, agar a) u kamida ikki marta tashrif buyurgan bo'lsa yoki xizmat ko'rsatilgan bo'lsa, hatto bir kun ichida bo'lsa b) u tashriflar bilan kamida ikki xil kun bo'lsa.",
    period_by_default: "Standart muddat",
    plan_treatment_text: "Davolash rejasida eslatma",
    PlanTreatment: "Davolash rejasi",
    popup: "Qalqimoq",
    price_per_additional_doctor: "Qo‘shimcha shifokor uchun narx",
    price_per_additional_space: "Qo‘shimcha joy uchun narx (100 Mb)",
    program_language: "Dastur tili",
    prolong: "Uzaytirish",
    purchase: "Sotib olish",
    record_tag: "Tashriflar uchun yorliq",
    record_tags: "Yorliqlarga tashrif buyuring",
    record_timing_enabled: "'Tashriflarning boshlanishi va tugashi' boshqaruv tizimi",
    Record: "Yozuv",
    RecordTooth: "Tish",
    recipient: "Qabul qilib oluvchi",
    reject: "Rad etish",
    required_fields_to_close_visit: "Tashrifni yopish uchun zarur maydonlar",
    requires_payment: "Qo‘shimcha to‘lovni talab qiladi",
    right: "Huquq",
    rights: "Huquqlar",
    salary_accrual: "Shifokorlar / administratorlar / kuratorlar uchun foiz o‘tkazish mantig‘i",
    salary_accrual_for_personnel: "Shifokorlar / ma'murlar / kuratorlar uchun ish haqini hisoblash",
    salary_accrual_for_technics: "Texniklarga ish haqi o‘tkazish",
    send_time: "Yuborish vaqti",
    sendouts_only_in_day_time: "Kunduzi ommaviy pochta jo'natish (09.00 - 22.00)",
    server_error: "Tizim sizning talabingizni bajara olmadi. Iltimos, qayta urinib ko‘ring yoki qo‘llab-quvvatlash xizmatiga murojaat qiling",
    services_import: "Xizmatlar importi",
    short_title: "Qisqartma nom",
    show_complex_materials_for_teeth: "To'plamlar va materiallarni davolash kartasidagi \"Tishlar\" yorlig'ida ko'rsating",
    show_description_in_forms: "Tashrif sharhini 043, 058 formatida ko'rsatish, davolash tarixi",
    show_images: "Rasmni ko'rsatish",
    show_preinstalled_templates: "O‘rnatilgan shablonlarni ko‘rsatish",
    show_teeth_general: "Davolash kartasidagi \"Tishlar\" yorlig'ida umumiy maydonlarni ko'rsating",
    sign_type: "Imzo turi",
    sort_order: "Saralash tartibi",
    source: "Manba",
    sources: "Manbalar",
    superadmin: "Superadministrator",
    superadministrators: "Superadministratorlar",
    supermanager: "Supermanager",
    super_user: "Superuser",
    switched_to_version_2: "2.0 versiyaga o‘tdi",
    sync_data: "Ma'lumotlarni sinxronlash",
    tag: "Yorliq",
    tag_id: "Yorliq",
    tags: "Belgilar",
    tags_for_custom_notifications: "Ma’lumotni avtomatik tarzda chiqarib olish uchun teglar:",
    tags_for_notifications: "Ma’lumotni avtomatik tarzda chiqarib olish uchun teglar:",
    tariff: "Tarif",
    tariff_payment_expires_in_days: "Tarif to'lovi bugun tugaydi | Tarif uchun to‘lov {day} kundan keyin tugaydi. | Tarif uchun to‘lov {day} kundan keyin tugaydi.",
    tariffs: "Tariflar",
    task_created: "Topshiriq yaratish",
    technic_salary_accrual: "Texniklar uchun qiziqishni hisoblash mantiqi",
    templates: "Shablonlar",
    templates_agreements: "Rozilik shablonlari",
    templates_categories: "Standart toifalar",
    templates_contracts: "Shartnoma shablonlar",
    templates_for_fields: "Maydonlar uchun shablonlar",
    templates_text: "Matn shablonlar",
    templates_treatments: "Davolash uchun shablonlar",
    techsupport_panel: "Texnik qo‘llab-quvvatlash paneli",
    test: "Test",
    this_may_take_several_minutes_do_not_close_the_window: "Bu bir necha daqiqa davom etishi mumkin. Iltimos, oyni yopmang",
    time_after_visit: "Tashrifdan so‘ng",
    time_before_visit: "tashrifgacha",
    time_interval_value: "Vaqt oralig‘i o‘lchami",
    time_zone: "Vaqt zonasi",
    to_billing: "Billing sozlamalariga",
    to_the_one_who_accepted_payment: "To‘lovni qabul qilgan kishiga",
    to_the_one_who_made_record: "Yozuvni amalga oshirga kishiga",
    treatment_finished: "Davolash tugadi",
    turn_on_autosign: "Avtomatik imzo qo‘yishni yoqish",
    turn_off: "O‘chirish",
    turned_off: "O‘chirilgan",
    turned_on: "Yoqilgan",
    'visit services sync': "Xizmatlarni bog‘lash",
    ukrainian: "Ukrain tili",
    unblock_for_3_days: "3 kunga blokdan ochish",
    updated: "O‘zgartirilgan",
    User: "Foydalanuvchi",
    use_bonuses_on_salary: "Bonus to'lovlari bilan ish haqi",
    use_proxy: "Proksi-serverdan foydalaning",
    user_id: "Foydalanuvchi Id",
    uzbek: "O'zbek",
    verify: "Tekshirish",
    visit_cancel: "Tashrifni bekor qilish",
    visit_comment: "Tashrif izohi",
    visit_created: "Tashrif yaratish",
    visit_results: "Tashrif natijalari",
    visit_result: "Tashrif natijasi",
    visit_type: "Tashrif turi",
    warehouse_connected: "Omborga pullik modul ulangan",
    week: "Hafta",
    when_changing_tariff_all_payments_will_nullify: "Agar siz tarifni o‘zgartirsangiz, barcha to‘lovlar bekor qilinadi",
    which_branches_use_online_booking: "Onlayn buyurtma qilish uchun qaysi filiallarni ishlatishni xohlaysiz?",
    which_clients_to_consider_permanent: "Kimga doimiy mijoz deb hisoblanganini tanlang",
    work_time_from: "dan boshlab ish vaqti",
    work_time_to: "gacha ish vaqti",
    your_current_ip: "Sizning joriy IP manzilingiz",
    your_tariff_plan_is_not_paid: "Sizning tarif rejangiz to‘lanmagan",
    zero_unlimited: "0 - cheksiz",
}
