import superadmin from "@/middleware/superadmin"
import onlySuperadmin from "@/middleware/onlySuperadmin"
import supermanager from "@/middleware/supermanager"
import superuser from "@/middleware/superuser"

import SuperAdminGeneral from "@/components/pages/superadmin/SuperAdminGeneral"
import SuperAdminClinics from "@/components/pages/superadmin/clinics/SuperAdminClinics"
import SuperAdminManagerClinic from "@/components/pages/superadmin/clinics/SuperAdminManagerClinic"
import SuperAdminTariffs from "@/components/pages/superadmin/tariffs/SuperAdminTariffs"
import SuperAdminPayments from "@/components/pages/superadmin/payments/SuperAdminPayments"
import SuperAdminNotifications from "@/components/pages/superadmin/notifications/SuperAdminNotifications"
import SuperAdminClinicPage from "@/components/pages/superadmin/clinics/SuperAdminClinicPage"
import SuperAdminClinicManagers from "@/components/pages/superadmin/clinics/SuperAdminClinicManagers"
import SuperAdminClinicServices from "@/components/pages/superadmin/clinics/SuperAdminClinicServices"
import SuperAdminClinicCategories from "@/components/pages/superadmin/clinics/SuperAdminClinicCategories"
import SuperAdminClinicRights from "@/components/pages/superadmin/clinics/SuperAdminClinicRights"
import SuperAdminClinicGeneral from "@/components/pages/superadmin/clinics/SuperAdminClinicGeneral"
import SuperAdminClinicSuperManagers from "@/components/pages/superadmin/clinics/SuperAdminClinicSuperManagers"
import SuperAdminClinicDoctors from "@/components/pages/superadmin/clinics/SuperAdminClinicDoctors"
import SuperAdminClinicAssistants from "@/components/pages/superadmin/clinics/SuperAdminClinicAssistants"
import SuperAdminClinicTechnics from "@/components/pages/superadmin/clinics/SuperAdminClinicTechnics"
import SuperAdminClinicPatients from "@/components/pages/superadmin/clinics/SuperAdminClinicPatients"
import SuperAdminClinicTariffs from "@/components/pages/superadmin/clinics/SuperAdminClinicTariffs"
import SuperAdminClinicBranches from "@/components/pages/superadmin/clinics/SuperAdminClinicBranches"
import SuperAdminClinicPrices from "@/components/pages/superadmin/clinics/SuperAdminClinicPrices"
import SuperAdminClinicImport from "@/components/pages/superadmin/clinics/SuperAdminClinicImport"
import SuperAdminClinicServicesImport from "@/components/pages/superadmin/clinics/SuperAdminClinicServicesImport"
import SuperAdminPopups from "@/components/pages/superadmin/notifications/SuperAdminPopups"
import SuperAdminAPI from "@/components/pages/superadmin/api/SuperAdminAPI"
import SuperAdminClinicIntegrations from "@/components/pages/superadmin/clinics/SuperAdminClinicIntegrations"
import SuperAdminClinicLogs from "@/components/pages/superadmin/clinics/SuperAdminClinicLogs"
import SuperAdminUsers from "@/components/pages/superadmin/users/SuperAdminUsers"
import SuperAdminDictionariesGeneral from "@/components/pages/superadmin/dictionaries/SuperAdminDictionariesGeneral"
import SuperAdminCountries from "@/components/pages/superadmin/dictionaries/SuperAdminCountries"
import SuperAdminCities from "@/components/pages/superadmin/dictionaries/SuperAdminCities"
import SuperAdminSources from "@/components/pages/superadmin/dictionaries/SuperAdminSources"
import SuperAdminPatientNotifications from "@/components/pages/superadmin/clinics/patients/SuperAdminPatientNotifications"
import SuperAdminClinicMarketingNotifications from "@/components/pages/superadmin/clinics/SuperAdminClinicMarketingNotifications"
import SuperAdminPaymentsGeneral from "@/components/pages/superadmin/payments/SuperAdminPaymentsGeneral"
import SuperAdminPayMethods from "@/components/pages/superadmin/payments/SuperAdminPayMethods"
import SuperAdminBillingCurrencyRates from "@/components/pages/superadmin/payments/SuperAdminBillingCurrencyRates"
import SuperAdminBillingServices from "@/components/pages/superadmin/payments/SuperAdminBillingServices"
import SuperAdminBonuses from "@/components/pages/superadmin/payments/SuperAdminBonuses"
import SuperAdminOnlineContractPayments from "@/components/pages/superadmin/payments/SuperAdminOnlineContractPayments"
import superadmin_specialist from "@/middleware/superadmin_specialist"
import superadminAccountManager from "@/middleware/superadminAccountManager"


export default {
    path: '/superadmin',
    meta: {middleware: [superuser], layout: "super_admin", rights: []},
    // component: () => import('../components/pages/superadmin/SuperAdminGeneral.vue'),
    component: SuperAdminGeneral,
    children: [
        { path: '', redirect: '/superadmin/clinics' },
        {
            path: 'clinics', name: "SuperAdminClinics",
            meta: { middleware: [superuser], layout: "super_admin", rights: [] },
            // component: () => import('../components/pages/superadmin/clinics/SuperAdminClinics.vue')
            component: SuperAdminClinics
        },
        {
            path: 'tariffs', name: "SuperAdminTariffs",
            meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
            component: SuperAdminTariffs
        },
        // {
        //     path: 'payments', name: "SuperAdminPayments",
        //     meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
        //     component: SuperAdminPayments
        // },
        {
            path: 'payments',
            meta: { middleware: [superadminAccountManager], layout: "super_admin", rights: [] },
            component: SuperAdminPaymentsGeneral,
            children: [
                { path: '', redirect: '/payments/list' },
                {
                    path: 'list', name: "SuperAdminPayments",
                    meta: { middleware: [superadminAccountManager], layout: "super_admin", rights: [] },
                    // component: () => import('../components/pages/warehouse/Warehouses.vue')
                    component: SuperAdminPayments
                },
                {
                    path: 'methods', name: "SuperAdminPayMethods",
                    meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
                    // component: () => import('../components/pages/warehouse/Groups.vue')
                    component: SuperAdminPayMethods
                },
                {
                    path: 'rates', name: "SuperAdminBillingCurrencyRates",
                    meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
                    // component: () => import('../components/pages/warehouse/Groups.vue')
                    component: SuperAdminBillingCurrencyRates
                },
                {
                    path: 'services', name: "SuperAdminBillingServices",
                    meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
                    component: SuperAdminBillingServices

                },
                {
                    path: 'bonuses', name: "SuperAdminBonuses",
                    meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
                    component: SuperAdminBonuses
                },
                {
                    path: 'online_contract_payments', name: "SuperAdminOnlineContractPayments",
                    meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
                    component: SuperAdminOnlineContractPayments
                },
            ]
        },
        {
            path: 'notifications', name: "SuperAdminNotifications",
            meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
            component: SuperAdminNotifications
        },
        {
            path: 'popups', name: "SuperAdminPopups",
            meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
            component: SuperAdminPopups
        },
        {
            path: 'api', name: "SuperAdminAPI",
            meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
            component: SuperAdminAPI
        },
        {
            path: 'users', name: "SuperAdminUsers",
            meta: { middleware: [onlySuperadmin], layout: "super_admin", rights: [] },
            component: SuperAdminUsers
        },
        {
            path: 'dictionaries',
            meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
            component: SuperAdminDictionariesGeneral,
            children: [
                { path: '', redirect: '/countries' },
                {
                    path: 'countries', name: "SuperAdminCountries",
                    meta: { middleware: [onlySuperadmin], layout: "super_admin", rights: [] },
                    component: SuperAdminCountries
                },
                {
                    path: 'cities', name: "SuperAdminCities",
                    meta: { middleware: [onlySuperadmin], layout: "super_admin", rights: [] },
                    component: SuperAdminCities
                },
                {
                    path: 'sources', name: "SuperAdminSources",
                    meta: { middleware: [onlySuperadmin], layout: "super_admin", rights: [] },
                    component: SuperAdminSources
                },
            ]
        },
        {
            path: 'login_logs', name: 'SuperAdminLoginLogs',
            meta: { middleware: [onlySuperadmin], layout: "super_admin", rights: [] },
            component: () => import('../components/pages/superadmin/clinics/SuperAdminLoginLogs.vue')
        },
        {
            path: 'developer', name: "SuperAdminDeveloper",
            meta: { middleware: [onlySuperadmin], layout: "super_admin", rights: [] },
            component: () => import('../components/pages/superadmin/SuperAdminDeveloper.vue')
            // component: SuperAdminDeveloper
        },
        {
            path: 'clinic/manager/:id', name: "SuperAdminManagerClinic",
            meta: { middleware: [supermanager], layout: "super_admin", rights: [] },
            // component: () => import('../components/pages/superadmin/payments/SuperAdminPayments.vue')
            component: SuperAdminManagerClinic
        },
        {
            path: 'clinic/:id',
            meta: { middleware: [superadmin_specialist], layout: "default", rights: [] },
            // component: () => import('../components/pages/superadmin/clinics/SuperAdminClinicPage.vue'),
            component: SuperAdminClinicPage,
            children: [
                // { path: '', redirect: '/clinic/:id/general' },
                { path: '', redirect: to => ({ name: 'SuperAdminClinicGeneral', params: { id: to.params.id } }) },
                {
                    path: 'general', name: "SuperAdminClinicGeneral",
                    meta: { middleware: [superadmin_specialist], layout: "super_admin", rights: [] },
                    // component: () => import('../components/pages/superadmin/clinics/SuperAdminClinicGeneral.vue')
                    component: SuperAdminClinicGeneral
                },
                {
                    path: 'rights', name: "SuperAdminClinicRights",
                    meta: { middleware: [superadmin_specialist], layout: "super_admin", rights: [] },
                    // component: () => import('../components/pages/superadmin/clinics/SuperAdminClinicRights.vue')
                    component: SuperAdminClinicRights
                },
                {
                    path: 'services', name: "SuperAdminClinicServices",
                    meta: { middleware: [superadmin_specialist], layout: "super_admin", rights: [] },
                    // component: () => import('../components/pages/superadmin/clinics/SuperAdminClinicServices.vue')
                    component: SuperAdminClinicServices
                },
                {
                    path: 'categories', name: "SuperAdminClinicCategories",
                    meta: { middleware: [superadmin_specialist], layout: "super_admin", rights: [] },
                    component: SuperAdminClinicCategories
                },
                {
                    path: 'managers', name: "SuperAdminClinicManagers",
                    meta: { middleware: [superadmin_specialist], layout: "super_admin", rights: [] },
                    component: SuperAdminClinicManagers
                },
                {
                    path: 'supermanagers', name: "SuperAdminClinicSuperManagers",
                    meta: { middleware: [superadmin_specialist], layout: "super_admin", rights: [] },
                    // component: () => import('../components/pages/superadmin/clinics/SuperAdminClinicSuperManagers.vue')
                    component: SuperAdminClinicSuperManagers
                },
                {
                    path: 'doctors', name: "SuperAdminClinicDoctors",
                    meta: { middleware: [superadmin_specialist], layout: "super_admin", rights: [] },
                    // component: () => import('../components/pages/superadmin/clinics/SuperAdminClinicDoctors.vue')
                    component: SuperAdminClinicDoctors
                },
                {
                    path: 'assistants', name: "SuperAdminClinicAssistants",
                    meta: { middleware: [superadmin_specialist], layout: "super_admin", rights: [] },
                    // component: () => import('../components/pages/superadmin/clinics/SuperAdminClinicManagers.vue')
                    component: SuperAdminClinicAssistants
                },
                {
                    path: 'technics', name: "SuperAdminClinicTechnics",
                    meta: { middleware: [superadmin_specialist], layout: "super_admin", rights: [] },
                    component: SuperAdminClinicTechnics
                },
                {
                    path: 'patients', name: "SuperAdminClinicPatients",
                    meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
                    // component: () => import('../components/pages/superadmin/clinics/SuperAdminClinicPatients.vue')
                    component: SuperAdminClinicPatients,
                },
                {
                    path: 'patients/:patientId/notifications',
                    name: "SuperAdminPatientNotifications",
                    meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
                    component: SuperAdminPatientNotifications,
                },
                {
                    path: 'tariffs', name: "SuperAdminClinicTariffs",
                    meta: { middleware: [superadmin_specialist], layout: "super_admin", rights: [] },
                    component: SuperAdminClinicTariffs
                },
                {
                    path: 'branches', name: "SuperAdminClinicBranches",
                    meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
                    component: SuperAdminClinicBranches
                },
                {
                    path: 'patients_import', name: "SuperAdminClinicImport",
                    meta: { middleware: [superadmin_specialist], layout: "super_admin", rights: [] },
                    component: SuperAdminClinicImport
                },
                {
                    path: 'services_import', name: "SuperAdminClinicServicesImport",
                    meta: { middleware: [superadmin_specialist], layout: "super_admin", rights: [] },
                    component: SuperAdminClinicServicesImport
                },
                {
                    path: 'integrations', name: "SuperAdminClinicIntegrations",
                    meta: { middleware: [superadmin_specialist], layout: "super_admin", rights: [] },
                    component: SuperAdminClinicIntegrations
                },
                {
                    path: 'prices', name: "SuperAdminClinicPrices",
                    meta: { middleware: [superadmin_specialist], layout: "super_admin", rights: [] },
                    component: SuperAdminClinicPrices
                },
                {
                    path: 'marketing_notifications', name: "SuperAdminClinicMarketingNotifications",
                    meta: { middleware: [superadmin_specialist], layout: "super_admin", rights: [] },
                    component: SuperAdminClinicMarketingNotifications
                },
                {
                    path: 'logs', name: "SuperAdminClinicLogs",
                    meta: { middleware: [superadmin_specialist], layout: "super_admin", rights: [] },
                    component: SuperAdminClinicLogs
                },
                {
                    path: 'agreements', name: "SuperAdminClinicAgreementTemplates",
                    meta: { middleware: [superadmin_specialist], layout: "super_admin", rights: [] },
                    component: () => import('../components/pages/superadmin/clinics/SuperAdminClinicAgreementTemplates.vue')
                },
                {
                    path: 'contracts', name: "SuperAdminClinicContractTemplates",
                    meta: { middleware: [superadmin_specialist], layout: "super_admin", rights: [] },
                    component: () => import('../components/pages/superadmin/clinics/SuperAdminClinicContractTemplates.vue')
                },
            ]
        },
    ]
}
